<template>
  <div class="flex flex-wrap justify-center">
    <div
      class="flex flex-wrap p-4 m-0"
      style="cursor: pointer"
      v-for="product in productGroups"
      :key="product.id"
    >
      <productCard
        :product="product"
        :smallImageHeight="imgHeight"
        @SetMaxHeightSmallImage="SetMaxHeightSmallImage"
      />
    </div>

    <div class="mt-6 noneProduct" v-if="productGroups.length == 0">
      Product not found, please check filter!
    </div>

    <!-- Loading -->
    <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <div
        id="div-with-loading"
        class="vs-con-loading__container loadingDiv"
      ></div>
    </vs-row>

    <observer v-on:intersect="intersected" />
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import productCard from "./ProductCard.vue";

import Observer from "../../Observer.vue";

export default {
  props: ["collection"],
  data() {
    return {
      productGroups: [],
      categoryId: null,
      nextPage: null,
      imgHeight: 0,
    };
  },
  methods: {
    SetMaxHeightSmallImage(height) {
      if (this.imgHeight < height) {
        this.imgHeight = height;
      }
    },
    async intersected() {
      // PAGINATION

      if (this.nextPage == null || this.productGroups.length == 0) {
        return;
      }

      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
      });

      var thisHere = this;

      setTimeout(function () {
        thisHere.GetProductGroups(true);
      }, 100);
    },
    GetProductGroups(next = false) {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      var params = {};

      if (this.categoryFilter && this.categoryFilter != "") {
        params.collection__category = this.categoryFilter;
      }

      if (this.collectionFilter && this.collectionFilter != "") {
        params.collection = this.collectionFilter;
      }

      if (this.stoneFilter) {
        params.collection__has_stone = this.stoneFilter;
      }
      if (this.orderingFilter && this.orderingFilter != "") {
        if (this.orderingFilter == "title") {
          params.ordering = "title";
        } else if (this.orderingFilter.substring(0, 1) == "-") {
          params.ordering = "-volume";
        } else {
          params.ordering = "volume";
        }
      } else {
        params.ordering = "-created";
      }

      if (this.minFilter && this.minFilter != "") {
        params.volume__gt = this.minFilter / this.selectedWeightMultipier.value;
      }

      if (this.maxFilter && this.maxFilter != "") {
        params.volume__lt = this.maxFilter / this.selectedWeightMultipier.value;
      }

      if (this.searchFilter && this.searchFilter != "") {
        params.search = this.searchFilter;
      }

      if (!next) {
        this.productGroups = [];
      }

      Api.get(
        next ? this.nextPage : API.BASEURL + API.NOKTA_MARKET_PRODUCT_GROUP,
        this.HandleGetProductGroups,
        params
      );
    },
    HandleGetProductGroups(status, data) {
      this.$vs.loading.close();
      this.$vs.loading.close("#div-with-loading > .con-vs-loading");

      if (status == 200) {
        if (this.productGroups.length == 0) {
          this.productGroups = data.results;
        } else {
          this.productGroups = this.productGroups.concat(data.results);
        }

        if (data.next == null) {
          this.nextPage = null;
        } else {
          this.nextPage = data.next;
        }
      }
    },
  },
  computed: {
    selectedWeightMultipier() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
    categoryFilter() {
      return this.$route.query.category;
    },
    collectionFilter() {
      return this.$route.query.collection;
    },
    stoneFilter() {
      return this.$route.query.has_stone;
    },
    orderingFilter() {
      return this.$route.query.ordering;
    },
    minFilter() {
      return this.$route.query.min;
    },
    maxFilter() {
      return this.$route.query.max;
    },
    searchFilter() {
      return this.$route.query.search;
    },
    queryTracker() {
      return this.$route.query;
    },
  },
  watch: {
    queryTracker() {
      this.GetProductGroups();
    },
    selectedWeightMultipier(newValue, oldValue) {
      if (oldValue.id != 0) {
        this.GetProductGroups();
      }
    },
  },
  created() {
    this.GetProductGroups();
    if (this.$route.query.category) {
      this.categoryId = this.$route.query.category;
    }
  },
  components: {
    productCard,
    Observer,
  },
};
</script>

<style scoped>
.noneProduct {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
}
.topBorderRadius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.loadingDiv {
  background-color: #f8f8f8;
  width: 100px;
  height: 100px;
}
</style>

<template>
  <div @click="OpenPopup" class="cardBackground">
    <vs-popup class="p-0" fullscreen title="" :active.sync="popupProductEnable">
      <div class="vx-row p-0 m-0">
        <div
          class="vx-col lg:w-1/2 justify-center items-center w-full p-4 p-0 m-0"
          style="align-self: center"
        >
          <swiper
            :options="swiperOption"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            :key="$vs.rtl"
          >
            <swiper-slide v-for="image in productImages" :key="image.id">
              <div
                class="flex items-center"
                :style="'height:' + maxImageHeight + 'px'"
              >
                <img
                  @load="SetMaxHeight"
                  class="popupImage"
                  v-lazy="image.image"
                  alt="banner"
                />
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
        <div
          class="vx-col flex flex-wrap lg:w-1/2 w-full content-between m-0 p-0"
        >
          <div class="w-full">
            <div class="flex flex-wrap justify-between">
              <h3 class="ml-4 flex" style="font-weight: 600">
                {{ product.title }}
                <vs-input-number
                  v-if="getDifferentProductTypeCount > 1"
                  class="ml-5"
                  max="999"
                  min="0"
                  color="primary"
                  v-model="allQuantity"
                  @input="ChangeAllQuantity"
                />
              </h3>
              <h3 class="ml-4 mr-4" style="font-weight: 600">
                Gold
                {{
                  (product.volume * selectedGoldWeightTypeStore.value).toFixed(
                    1
                  )
                }}
                Gr ({{ selectedGoldWeightTypeStore.weightType }})
              </h3>
            </div>

            <hr class="m-4" />

            <div class="vx-row p-0 m-0">
              <div
                class="vx-col w-full xl:w-1/2 lg:w-full md:w-1/2 p-0 m-0 pl-4 pr-4"
                v-for="type in filteredTypeList"
                :key="type.type"
              >
                <div class="flex justify-between typeArea">
                  <p>
                    {{ type.type }}
                  </p>
                  <vs-input-number
                    class="ml-5"
                    max="999"
                    min="0"
                    color="primary"
                    v-model="type.quantity"
                    @input="ChangeSelectedTypeQuantity(type)"
                  />
                </div>
                <div
                  style="align-items: center"
                  v-for="subProduct in productDetail"
                  :key="subProduct.id"
                >
                  <div
                    v-if="subProduct.type == type.type"
                    class="flex justify-between items-center w-full p-0 m-0"
                  >
                    <p style="font-weight: 600; color: #000">
                      {{ subProduct.title }}
                      ({{
                        (
                          subProduct.volume * selectedGoldWeightTypeStore.value
                        ).toFixed(2)
                      }}gr)
                    </p>

                    <productQuantity
                      class="p-0"
                      :ref="'productQuantityAreaRef' + subProduct.id"
                      :id="subProduct.id"
                      :quantity="subProduct.quantity"
                      @ChangeQuantity="ChangeQuantity"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mt-6">
            <div class="w-full flex justify-between">
              <div></div>
              <vs-button
                style="display: none"
                color="dark"
                class="mr-4"
                @click="AddToBasket"
                >ADD TO BASKET</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <div
      class="flex items-center"
      :style="smallImageHeight != 0 ? 'height:' + smallImageHeight + 'px' : ''"
    >
      <!-- TODO  IMAGE order en kücüge göre sırala -->
      <img
        @load="SetMaxHeightSmallImage"
        :src="GetFirstImage()"
        class="p-4"
        alt="product image"
        style="height: 100%"
      />
    </div>
    <hr />
    <div class="flex justify-between">
      <div class="p-2" style="font-weight: 600">
        {{ product.title }}
      </div>
      <div class="p-2" style="font-weight: 600">
        {{ selectedGoldWeightTypeStore.weightType }} -
        {{ (product.volume * selectedGoldWeightTypeStore.value).toFixed(1) }} gr
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import productQuantity from "./ProductQuantityArea.vue";

export default {
  props: ["product", "smallImageHeight"],
  data() {
    return {
      maxImageHeight: 0,
      typeOrder: [
        {
          type: "",
          quantity: 0,
        },
        {
          type: null,
          quantity: 0,
        },
        {
          type: "N",
          quantity: 0,
        },
        {
          type: "R",
          quantity: 0,
        },
        {
          type: "E",
          quantity: 0,
        },
        {
          type: "B",
          quantity: 0,
        },
        {
          type: "CH",
          quantity: 0,
        },
        {
          type: "P",
          quantity: 0,
        },
        {
          type: "CM",
          quantity: 0,
        },
      ],
      allQuantity: 0,

      noImage: require("@/assets/images/pages/no_image_250.png"),

      filteredTypeList: [],

      productDetail: [],
      productImages: [],
      popupProductEnable: false,
      swiperOption: {
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    SetMaxHeightSmallImage(event) {
      this.$emit("SetMaxHeightSmallImage", event.target.clientHeight * 0.75);
    },
    SetMaxHeight(event) {
      let div = event.target;
      if (div.clientHeight > this.maxImageHeight) {
        this.maxImageHeight = div.clientHeight;
      }
    },
    GetFirstImage() {
      if (this.product.images.length > 0) {
        let min = Math.min(...this.product.images.map((item) => item.order));

        return this.product.images.filter((item) => item.order === min)[0]
          .image_thumbnail;
      } else {
        return this.noImage;
      }
    },
    AddToBasket() {
      let totalQuantity = 0;
      this.productDetail.forEach((element) => {
        totalQuantity += element.quantity;
      });

      if (totalQuantity == 0) {
        this.$vs.notify({
          title: "Warning",
          text: "Product quantity not selected!",
          color: "danger",
          time: 3000,
        });
        return;
      }

      this.productDetail.forEach((element) => {
        if (element.quantity > 0) {
          this.$store.dispatch("noktaMarket/addProductToBasket", {
            ...element,
            sizeSuitability: true,
          });
          let index = this.getBasketProductGroups.findIndex(
            (e) => e.id == this.product.id
          );

          if (index == -1) {
            this.$store.dispatch("noktaMarket/addProductGroup", {
              id: this.product.id,
              title: this.product.title,
              image: this.GetFirstImage(),
            });
          }
        }
      });
      this.$vs.notify({
        color: "success",
        title: "Added to basket",
        text: this.product.title + " added to basket",
      });
      this.popupProductEnable = false;
    },
    ChangeAllQuantity() {
      this.filteredTypeList.forEach((element) => {
        element.quantity = this.allQuantity;
        this.ChangeSelectedTypeQuantity({
          type: element.type,
          quantity: this.allQuantity,
        });
      });
    },
    ChangeSelectedTypeQuantity(type) {
      for (let index = 0; index < this.productDetail.length; index++) {
        const element = this.productDetail[index];
        if (element.type == type.type) {
          if (element.type != "R") {
            this.ChangeQuantity({ id: element.id, quantity: type.quantity });
          } else {
            if (element.title.slice(-2) == "58") {
              this.ChangeQuantity({ id: element.id, quantity: type.quantity });
            }
          }
        }
      }
    },

    ChangeQuantity(params) {
      this.productDetail.forEach((element) => {
        if (element.id == params.id) {
          element.quantity = parseInt(params.quantity);
          let ref = this.$refs["productQuantityAreaRef" + params.id][0];
          ref.ChangedQuantity(params.quantity);
        }
      });
    },
    ImageCompare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },
    CheckProductImage() {
      if (!this.product.images.image_thumbnail) {
        this.product.images.image_thumbnail = this.noImage;
      }
    },
    ProductListWithType(type) {
      var tempList = [];

      this.productDetail.forEach((product) => {
        if (product.type == type) {
          tempList.push(product);
        }
      });

      return tempList;
    },
    OpenPopup() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      this.GetProductDetail();
    },
    GetProductDetail() {
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PRODUCT_GROUP + this.product.id + "/",
        this.HandleGetProductDetail
      );
    },
    HandleGetProductDetail(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.popupProductEnable = true;

        this.productImages = data.images.sort(this.ImageCompare);
        this.productDetail = data.results;
        this.productDetail.forEach((element) => {
          if (element.type != "R") {
            element.quantity = 0;
          } else {
            if (element.title.slice(-2) == "58") {
              element.quantity = 0;
            } else {
              element.quantity = 0;
            }
          }
        });
      }
      this.ProductTypeList();
    },
    ProductTypeList() {
      let productTypeList = [];
      this.productDetail.forEach((product) => {
        if (!productTypeList.includes(product.type)) {
          productTypeList.push(product.type);
        }
      });

      const filteredArray = this.typeOrder.filter((value) =>
        productTypeList.includes(value.type)
      );

      this.filteredTypeList = filteredArray;
    },
  },

  computed: {
    getDifferentProductTypeCount() {
      let differentType = [];
      if (this.productDetail.length > 0) {
        differentType.push(this.productDetail[0].type);
      }
      this.productDetail.forEach((element) => {
        if (!differentType.includes(element.type)) {
          differentType.push(element.type);
        }
      });

      return differentType.length;
    },
    selectedGoldWeightTypeStore() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
    getBasketProductGroups() {
      return this.$store.getters["noktaMarket/productGroups"];
    },
  },
  watch: {
    popupProductEnable(newValue) {
      if (newValue == false) {
        this.productDetail = [];
        this.allQuantity = 0;
        this.typeOrder.forEach((element) => {
          element.quantity = 0;
        });
      }
    },
  },

  components: {
    swiper,
    swiperSlide,
    productQuantity,
  },
  created() {
    this.CheckProductImage();
  },
};
</script>

<style lang="scss">
@media (min-width: 736px) {
  .con-vs-popup.fullscreen .vs-popup {
    width: 80% !important;
    height: auto !important;
    .popupImage {
      width: 100%;
      height: auto;
    }
  }
}
@media (max-width: 736px) {
  .con-vs-popup.fullscreen .vs-popup {
    width: 100% !important;
    height: auto !important;
    .popupImage {
      width: 100%;
      height: auto;
    }
  }
}

.vs-input-number {
  margin: 2px 10px;
}
</style>

<style scoped>
.typeArea {
  align-items: center;
  color: #c89e82;
  font-weight: 600;
}
.cardBackground {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
</style>

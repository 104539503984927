<template>
  <div>
    <vs-input-number
      style="width: fit-content"
      size="medium"
      max="999"
      min="0"
      color="dark"
      v-model="tmpQuantity"
      @input="
        $emit('ChangeQuantity', {
          id: id,
          quantity: tmpQuantity,
        })
      "
    />
  </div>
</template>

<script>
export default {
  props: ["id", "quantity"],
  data() {
    return {
      tmpQuantity: this.quantity,
    };
  },
  methods: {
    ChangedQuantity(quantity) {
      this.tmpQuantity = quantity;
    },
  },
};
</script>

<style></style>

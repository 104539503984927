<template>
  <div class="pl-4 pr-4">
    <vs-popup class="p-0" title="Agreement" :active.sync="agreementConfirm">
      <div class="p-8">
        <h1>Non Disclosure Agreement</h1>

        This Non Disclosure and Confidentiality Agreement ("Agreement") is
        entered into by and between ABC Company, hereinafter known as the
        "Disclosing Party", and the undersigned receiving party (the "Receiving
        Party"), and collectively both parties known as "Parties". WHEREAS, the
        Disclosing Party shall disclose or deliver tangible or intangible
        information to the Receiving party that may be considered confidential
        or proprietary information; WHEREAS, the Disclosing Party desires to be
        assured that the proprietary information owned by them shall not be
        disclosed to any third party without their consent and shall be
        maintained and protected by the Receiving Party; NOW, THEREFORE, in
        consideration of the mutual promises and covenants included in this
        agreement, both parties agree as follows:
        <h2>I. Definition.</h2>
        <p>
          Confidential Information shall refer to any kind of information
          whether oral or written made by or provided for by the Disclosing
          Party to the Receiving Party or vice versa. It could be written or in
          oral communication that may refer to any patent, copyright, trademark,
          or trade secret. The information under this agreement to be declared
          or constituted as Confidential by the Disclosing Party, regardless of
          whether such information was provided before or after the date of this
          Agreement shall be, but not limited to the following:
        </p>

        <h4>1. Marketing and Development Information -</h4>
        <p>
          This includes plans of the Disclosing Party about marketing agenda or
          business developments and may involve costing, policies, quotes,
          forecasts, and strategies.
        </p>

        <h4>2. Business Operations Information -</h4>
        This refers to any information about the business operations of the
        party disclosing the information, including the number of personnel, the
        financial activities within, the information on vendors, providers, and
        partners that are not openly declared by the Party to the public.

        <h4>3. Product Information -</h4>
        This refers to all specifications for products produced by the
        Disclosing Party as well as the products resulting from or related to
        work or projects performed or to be performed for the Disclosing Party
        or for its clients, or information about the product during research and
        development;
        <h4>4. Manufacturing and Production Processes -</h4>
        This includes identified processes and systematic creation of product
        output such as manufacturing or production such as, but not limited to
        formulas, technical procedures, methods, techniques, specifications,
        including the used devices and equipment, designs, models, or software
        files used for manufacturing products. This may likewise refer to
        Proprietary Information;
        <h4>5. Service Information -</h4>
        This refers to information relating to services provided by the
        Disclosing Party such as but not limited to manpower scheduling,
        training, and maintenance;
        <h4>6. Intellectual Property -</h4>
        This refers to all information creation of the mind such as inventions,
        designs, symbols, names, and images created for commerce, such as but
        not limited to logos, computer codes and algorithms both in
        human-readable and machine-readable form, reports, technical designs,
        database structure, charts, and other document design specifications,
        including music which may be subject to copyright.
        <h4>7. Technology -</h4>
        This refers to all technical and scientific information and materials
        produced from formulation made by the Disclosing Party and by which
        information has not been publicly used, including to use of any machine,
        appliance, or components, including the documented scientific process.
        <h4>8. Accounting Information -</h4>
        This includes all financial reports, worksheets, balance sheets,
        undisclosed assets and liabilities, inventories, payroll, and any other
        information that may relate to a financial activity and by which
        information is not disclosed or that such disclosure has not been
        consented by the Disclosing Party. Confidential Information may likewise
        include any information disclosed by any party to the Disclosing Party
        that is protected by a non-disclosure agreement and by which has been
        accidentally, incidentally, or acquired by the Receiving Party whether
        directly or indirectly.
        <h2>II. Obligations.</h2>
        The Receiving Party, upon acquiring the confidential information shall
        hold in trust and confidence the information possessed by said party and
        shall not disclose it to any other party or use it for its own benefit
        or any other without the consent of the Disclosing Party. The Receiving
        Party may not disassemble, decompile, or reverse engineer products,
        prototypes, source codes, software, or any other objects that have been
        shared or provided for by Disclosing Party's that may contain
        Confidential Information and that are provided to the Receiving Party
        for purposes under this agreement. The Receiving Party shall not
        likewise make copies, of said information unless consent has been given
        by the Disclosing Party. This clause shall survive and continue during
        and after the expiration or termination of this Agreement.
        <h2>III. Exclusions From Confidential Information.</h2>
        The Receiving Party shall not need the consent of the Disclosing Party
        to share, disclose, or copy the Confidential Information in cases where:
        <br />
        1. The Confidential Information, at the time of disclosure, such
        information is already publicly known;
        <br />
        2. Information received by the Receiving Party has already been known by
        the Receiving Party prior to its disclosure;
        <br />
        3. The Confidential Information has been obtained by Receiving Party
        rightfully from any other party other than the Disclosing Party and
        without reason to believe that the third party is under any obligation
        or has an agreement with the Disclosing Party; <br />
        4. The Confidential Information was developed by Recipient without
        reference or dependence to the Disclosing Party's Confidential
        Information.
        <h2>IV. Non-Compete.</h2>
        During the effectivity and within five (5) years after the expiration of
        this agreement, the Receiving Party understands and agrees to not be
        involved directly or indirectly, in activities that are in competition
        with the Disclosing Party.
        <h2>V. Return of Confidential Information.</h2>
        Upon termination of this Agreement and/or upon request of the Disclosing
        Party, all Confidential Information disclosed under this Agreement shall
        be returned to the Disclosing Party. Physical records, or any hardcopy
        material, print media, or any other physical materials containing
        Confidential Information shall be delivered or return to the Disclosing
        Party. Digital records shall be deleted and no copies shall be retained
        by the Receiving Party. In case of impossibility of the delivery of
        Confidential Information by the Receiving Party to the Disclosing Party,
        the parties may unanimously elect for the destruction of any records,
        documents, whether physical or digital containing the Confidential
        Information. In case of destruction of Confidential Information,
        destruction shall be certified in writing to the Disclosing Party after
        the destruction of such Confidential Information before the presence of
        a duly authorized representative of the Disclosing Party and/or by one
        of Recipient’s duly authorized officers.
        <h2>VI. Ownership.</h2>
        This Non Disclosure Agreement is not transferable. The written content
        of both parties is needed in order to transfer this Agreement. The
        Parties acknowledges that each party shall maintain its exclusive
        ownership over its own Confidential Information except as otherwise
        falling under the found in the Exclusions from Confidential Information
        clause. Neither party may obtain rights whatsoever, by license or
        otherwise, in the other party's Confidential Information unless
        otherwise with consent provided for by the other party. Any
        recommendation of any information made by the Receiving Party to the
        Disclosing Party that shall be incorporated to the Confidential
        Information of the Disclosing Party shall form part of the Disclosing
        party's Confidential Information.
        <h2>VII. Assignment.</h2>
        Neither party may assign or delegate any of its rights, or delegate any
        of its obligations hereunder, without the prior written consent of the
        other party.
        <h2>VIII. General.</h2>
        All additions or modifications to this Agreement must be made in writing
        and signed by both parties. The effectivity date of this Agreement is
        the same as the execution date.
        <h2>IX. Warranties.</h2>
        The Disclosing Party makes no representation or warranties whatsoever to
        the Receiving Party as to the accuracy, completeness, or performance of
        any such Confidential Information. No party shall become liable for the
        use of the Confidential Information.
        <h2>X. Injunctive Relief.</h2>
        The Parties agree that in the event of any breach due to careless
        handling of the Confidential Information, the aggrieved party shall be
        entitled to seek injunctive relief for the protection of such party from
        incurring further damage or which may result to an irreparable injury.
        <h2>XI. Counterparts.</h2>
        The Parties may execute this agreement in multiple counterparts, each of
        which is an original but all of which shall constitute one and the same
        instrument.
        <h2>XII. Entire Agreement.</h2>
        This Agreement is understood to be the complete agreement of the parties
        hereto and shall supersede any prior agreements and discussions relating
        to the subject matter hereof. Any amendments, modifications, or
        alterations made in this agreement shall require the affirmation by both
        parties to this Agreement and signed by said parties hereto.
        <h2>XIII. Separability Clause.</h2>
        Should any of the provisions hereto be held invalid by any competent
        court, such invalidity shall affect only the said provision and the
        remaining provisions shall continue to be valid and enforceable.
        <h2>XIV. Governing Laws.</h2>
        This Agreement shall be governed and construed in accordance with the
        laws of the United States, to the exclusion of other state laws and
        without any regard to its conflicts of laws provisions. Any disputes
        arising from in this agreement shall be filed with courts of the United
        States. Each party hereby consents to the jurisdiction of said courts
        and waives any objection which they may have at any time to the
        jurisdiction of such courts, the laying of venue in such courts or the
        convenience of the forum. IN WITNESS WHEREOF, the Parties have set their
        hands on the date specified.
      </div>
      <div class="p-8">
        <vs-checkbox>I have read the contract.</vs-checkbox>
      </div>
      <div class="p-8 text-right">
        <vs-button @click="agreementConfirm = !agreementConfirm">
          Confirm
        </vs-button>
      </div>
    </vs-popup>

    <div class="vx-row whiteBackground p-2">
      <div class="vx-col">
        <div class="barTitles">Gold Weight</div>
        <div class="flex">
          <v-select
            style="min-width: 85px"
            class="mr-4"
            :options="showedWeightMultipier"
            label="weightType"
            v-model="selectedGoldWeightType"
            @input="UpdateGoldWeightType()"
          >
          </v-select>
          <vs-input
            :disabled="selectedCategory == null && !searchedFilter"
            class="goldWeightInputWidth mr-1"
            placeholder="Min"
            v-model="volumeMin"
          ></vs-input>
          <p style="font-weight: 600; align-self: center">-</p>
          <vs-input
            :disabled="selectedCategory == null && !searchedFilter"
            class="goldWeightInputWidth ml-1 mr-4"
            placeholder="Max"
            v-model="volumeMax"
          ></vs-input>
          <vs-button
            :disabled="selectedCategory == null && !searchedFilter"
            type="filled"
            icon="play_arrow"
            @click="FilterVolume"
          ></vs-button>
        </div>
      </div>

      <div class="grayLine mt-2 mb-2 sm:block hidden"></div>

      <div class="vx-col">
        <div class="barTitles">Stones</div>
        <div class="flex mt-2" style="color: #707070; font-weight: 600">
          <vs-checkbox
            :disabled="selectedCategory == null && !searchedFilter"
            v-model="withStone"
            @input="FilterStone('with')"
            >With</vs-checkbox
          >
          <vs-checkbox
            :disabled="selectedCategory == null && !searchedFilter"
            v-model="withoutStone"
            @input="FilterStone('without')"
            >Without</vs-checkbox
          >
        </div>
      </div>

      <div class="grayLine mt-2 mb-2 sm:block hidden"></div>

      <div class="vx-col flex items-center">
        <div class="vx-row">
          <div class="vx-col mr-2" style="width: 240px">
            <v-select
              :disabled="selectedCategory == null && !searchedFilter"
              class="mr-4 w-full mt-2"
              v-model="selectedSortOption"
              :placeholder="$t('Sort_By')"
              :options="sortOptions"
            ></v-select>
          </div>

          <div class="vx-col" style="width: 240px">
            <vs-input
              class="w-full mt-2"
              icon="search"
              v-model="searchFilter"
              @keypress.enter="FilterSearch"
            ></vs-input>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        minFilter ||
        maxFilter ||
        stoneFilter ||
        orderingFilter ||
        searchedFilter
      "
      class="vx-row mt-2"
    >
      <vs-chip
        v-if="minFilter"
        color="primary"
        @click="RemoveFilter('volumeMin')"
        closable
      >
        Min: {{ minFilter }}
      </vs-chip>
      <vs-chip
        v-if="maxFilter"
        color="primary"
        @click="RemoveFilter('volumeMax')"
        closable
      >
        Max: {{ maxFilter }}
      </vs-chip>

      <vs-chip
        v-if="stoneFilter"
        color="primary"
        @click="RemoveFilter('stoneFilter')"
        closable
      >
        Stone:
        <vs-icon
          :icon="stoneFilter == 'true' ? 'done' : 'clear'"
          size="small"
          :color="stoneFilter == 'true' ? 'green' : 'red'"
        ></vs-icon>
      </vs-chip>
      <vs-chip
        v-if="orderingFilter"
        color="primary"
        @click="RemoveFilter('orderingFilter')"
        closable
      >
        Sort: {{ sortOptions.filter((e) => e.id == orderingFilter)[0].label }}
      </vs-chip>

      <vs-chip
        v-if="searchedFilter"
        color="primary"
        @click="RemoveFilter('search')"
        closable
      >
        Search: {{ searchedFilter }}
      </vs-chip>

      <vs-button
        class="pt-2 pb-2 pl-3 pr-3 ml-2"
        @click.native="RemoveFilter('all')"
      >
        Clear All
      </vs-button>
    </div>

    <!-- breadcrumb -->
    <div
      v-if="!searchFilterQuery"
      class="mt-4 flex"
      style="font-size: 1.2rem; font-weight: 500; align-items: center"
    >
      <p style="cursor: pointer" @click="ClearCategory">All Designs</p>

      <p class="ml-2" v-if="selectedCategory != null">></p>

      <p
        v-if="selectedCategory != null"
        class="ml-2"
        :style="
          selectedCollection == null
            ? 'font-weight: 700; font-size: 1.4rem'
            : 'cursor: pointer'
        "
        @click="ClearCollection"
      >
        {{ selectedCategory.title }}
      </p>

      <p class="ml-2" v-if="selectedCollection != null">></p>

      <p
        v-if="selectedCollection != null"
        class="ml-2"
        :style="
          selectedProduct == null ? 'font-weight: 700; font-size: 1.4rem' : ''
        "
      >
        {{ selectedCollection.title }}
      </p>
    </div>

    <div class="w-full">
      <categories
        v-if="selectedCategory == null && !searchFilterQuery"
        @changeCategory="ChangeCategory"
      />
    </div>

    <div>
      <collections
        v-if="
          selectedCategory != null &&
          selectedCollection == null &&
          !searchFilterQuery
        "
        :category="selectedCategory"
        @changeCollection="ChangeCollection"
      />
    </div>
    <div>
      <products
        v-if="
          (selectedCategory != null && selectedCollection != null) ||
          searchFilterQuery
        "
        :collection="selectedCollection"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

import Collections from "./components/Collections.vue";
import categories from "./components/AllDesigns.vue";
import products from "./components/Products.vue";

export default {
  data() {
    return {
      agreementConfirm: false,
      searchFilter: null,
      volumeMin: null,
      volumeMax: null,
      withStone: false,
      withoutStone: false,
      selectedGoldWeightType: null,
      sortOptions: [
        {
          id: "-created",
          label: this.$t("Newest_Products"),
        },
        {
          id: "title",
          label: this.$t("Sort_By_Name"),
        },
        {
          id: "average_volume",
          label: this.$t("Light_Products_First"),
        },
        {
          id: "-average_volume",
          label: this.$t("Heavy_Products_First"),
        },
      ],
      selectedSortOption: {
        id: "-created",
        label: this.$t("Newest_Products"),
      },
      selectedCategory: null,
      selectedCollection: null,
      selectedProduct: null,
    };
  },
  methods: {
    GetSortOption(id) {
      return this.sortOptions.filter((e) => e.id == id)[0];
    },
    RemoveFilter(filter) {
      let params = JSON.parse(JSON.stringify(this.$route.query));
      if (filter == "search") {
        this.searchFilter = null;
        delete params.search;
      } else if (filter == "volumeMin") {
        this.volumeMin = null;
        delete params.min;
      } else if (filter == "volumeMax") {
        this.volumeMax = null;
        delete params.max;
      } else if (filter == "stoneFilter") {
        this.withStone = false;
        this.withoutStone = false;
        delete params.has_stone;
      } else if (filter == "orderingFilter") {
        this.selectedSortOption = {
          id: "-created",
          label: this.$t("Newest_Products"),
        };
        delete params.ordering;
      } else if (filter == "all") {
        this.searchFilter = null;
        delete params.search;
        this.volumeMin = null;
        delete params.min;
        this.volumeMax = null;
        delete params.max;
        this.withStone = false;
        this.withoutStone = false;
        delete params.has_stone;
        this.selectedSortOption = {
          id: "-created",
          label: this.$t("Newest_Products"),
        };
        delete params.ordering;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    FilterSearch() {
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));

      if (this.searchFilter != null && this.searchFilter != "") {
        params.search = this.searchFilter;
        delete params.category;
        delete params.collection;
      } else {
        delete params.search;
      }
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    FilterVolume() {
      if (
        this.volumeMin != null &&
        this.volumeMin != "" &&
        this.volumeMax != null &&
        this.volumeMax != ""
      ) {
        this.volumeMin = parseInt(this.volumeMin);
        this.volumeMax = parseInt(this.volumeMax);
        if (this.volumeMin > this.volumeMax) {
          let tmp = this.volumeMin;
          this.volumeMin = this.volumeMax;
          this.volumeMax = tmp;
        }
      }
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      if (this.volumeMin != null && this.volumeMin != "") {
        params.min = this.volumeMin;
      } else {
        delete params.min;
      }

      if (this.volumeMax != null && this.volumeMax != "") {
        params.max = this.volumeMax;
      } else {
        delete params.max;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    FilterStone(filter) {
      if (filter == "with") {
        this.withoutStone = false;
      } else {
        this.withStone = false;
      }
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      if (this.withStone) {
        params.has_stone = true;
        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else if (this.withoutStone) {
        params.has_stone = false;
        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else {
        if (params.has_stone) {
          delete params.has_stone;
        }
        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
    UpdateGoldWeightType() {
      var data = {
        nokta_market_gold_type: this.selectedGoldWeightType.weightType.slice(
          0,
          2
        ),
      };
      Api.patch(
        API.BASEURL + API.NOKTA_MARKET_GOLD_TYPE,
        this.HandleUpdateGoldWeightType,
        data
      );
    },
    HandleUpdateGoldWeightType(status) {
      if (status != 200) {
        this.$vs.notify({
          title: "Error",
          text: "Something went wrong",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    ClearCategory() {
      this.selectedCategory = null;
      this.selectedCollection = null;
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      delete params.category;
      delete params.collection;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    ClearCollection() {
      this.selectedCollection = null;
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      delete params.collection;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    ChangeCategory(category) {
      this.selectedCategory = category;
    },
    ChangeCollection(collection) {
      this.selectedCollection = collection;
    },
  },
  computed: {
    query() {
      return this.$route.query;
    },
    selectedGoldWeightTypeStore() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
    showedWeightMultipier() {
      return this.$store.getters["noktaMarket/showedWeightMultipier"];
    },
    minFilter() {
      return this.$route.query.min;
    },
    maxFilter() {
      return this.$route.query.max;
    },
    stoneFilter() {
      return this.$route.query.has_stone;
    },
    orderingFilter() {
      return this.$route.query.ordering;
    },
    searchedFilter() {
      return this.$route.query.search;
    },
    searchFilterQuery() {
      return this.$route.query.search ? true : false;
    },
  },
  watch: {
    searchedFilter(newValue) {
      if (newValue == null) {
        let params = JSON.parse(JSON.stringify(this.$route.query));

        this.volumeMin = null;
        delete params.min;
        this.volumeMax = null;
        delete params.max;
        this.withStone = false;
        this.withoutStone = false;
        delete params.has_stone;
        this.selectedSortOption = {
          id: "-created",
          label: this.$t("Newest_Products"),
        };
        delete params.ordering;

        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
    selectedSortOption(newValue) {
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      if (newValue.id == "-created") {
        delete params.ordering;
      } else {
        params.ordering = this.selectedSortOption.id;
      }
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    query(newValue) {
      if (!newValue.hasOwnProperty("category")) {
        this.selectedCategory = null;
      }
      if (!newValue.hasOwnProperty("collection")) {
        this.selectedCollection = null;
      }
      if (!newValue.hasOwnProperty("min")) {
        this.volumeMin = null;
      }
      if (!newValue.hasOwnProperty("max")) {
        this.volumeMax = null;
      }
      if (!newValue.hasOwnProperty("has_stone")) {
        this.withStone = false;
        this.withoutStone = false;
      }
      if (!newValue.hasOwnProperty("ordering")) {
        this.selectedSortOption = {
          id: "-created",
          label: this.$t("Newest_Products"),
        };
      }
    },
    selectedGoldWeightTypeStore() {
      this.selectedGoldWeightType = this.selectedGoldWeightTypeStore;
    },
    selectedGoldWeightType() {
      this.$store.commit(
        "noktaMarket/CHANGE_SELECTED_WEIGHT_MULTIPIER",
        this.selectedGoldWeightType
      );
    },
  },
  mounted() {
    this.selectedGoldWeightType = this.selectedGoldWeightTypeStore;
    if (this.searchedFilter) {
      this.searchFilter = this.searchedFilter;
    }
    if (this.minFilter) {
      this.volumeMin = this.minFilter;
    }
    if (this.maxFilter) {
      this.volumeMax = this.maxFilter;
    }
    if (this.stoneFilter) {
      if (this.stoneFilter == "true") {
        this.withStone = true;
      } else {
        this.withoutStone = true;
      }
    }
    if (this.orderingFilter) {
      this.selectedSortOption = this.GetSortOption(this.orderingFilter);
    }
  },
  components: {
    vSelect,
    categories,
    Collections,
    products,
  },
};
</script>

<style scoped>
.goldWeightInputWidth {
  max-width: 50px;
}
.barTitles {
  font: normal normal normal Museo Sans;
  color: #434343;
  font-weight: 600;
  padding-bottom: 0.5rem;
}
.whiteBackground {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.grayLine {
  border: 1px solid #dedede;
}
</style>

<style lang="scss">
.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}

.vs-input--icon {
  font-size: 1.5rem;
}
</style>

<template>
  <div class="vx-row p-0">
    <div
      class="vx-col lg:w-1/5 md:w-1/3 sm:w-1/2 w-full p-4 m-0"
      v-for="collection in collections"
      :key="collection.id"
    >
      <div style="cursor: pointer" @click="SendCollection(collection)">
        <div class="flex" style="position: relative">
          <!-- TODO New Eklenecek -->
          <!-- <div v-if="collection.new" class="newDiv">NEW</div> -->
          <img :src="collection.image_thumbnail" class="w-full" alt="" />
        </div>
        <div class="collectionDetail flex justify-center pt-2 pb-2">
          <p style="font-weight: 600; font-size: 1rem">
            {{ collection.title }}
          </p>
        </div>
        <div
          class="collectionDetail flex justify-between pb-4 pl-2 pr-2"
          style="font-size: 0.8rem"
        >
          <p style="font-weight: 600">
            {{ collection.product_groups_count }}
            Product
          </p>
          <p>
            Avg({{ selectedWeightMultipier.weightType }})
            {{
              (
                collection.average_volume * selectedWeightMultipier.value
              ).toFixed(2)
            }}
            gr
          </p>
        </div>
      </div>
    </div>

    <!-- Loading -->
    <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <div
        id="div-with-loading"
        class="vs-con-loading__container loadingDiv"
      ></div>
    </vs-row>

    <observer v-on:intersect="intersected" />
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import Observer from "../../Observer.vue";

export default {
  props: ["category"],
  data() {
    return {
      collections: [],
      nextPage: null,
    };
  },
  methods: {
    async intersected() {
      // PAGINATION

      if (this.nextPage == null) {
        return;
      }

      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
      });

      var thisHere = this;

      setTimeout(function () {
        thisHere.GetCollection(true);
      }, 100);
    },
    SendCollection(collection) {
      this.$emit("changeCollection", collection);
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      params.collection = collection.id;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    GetCollection(next = false) {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      let params = {};
      if (this.stoneFilter) {
        params.has_stone = this.stoneFilter;
      }
      if (this.orderingFilter && this.orderingFilter != "") {
        params.ordering = this.orderingFilter;
      }

      if (this.minFilter && this.minFilter != "") {
        params.average_volume__gt =
          this.minFilter / this.selectedWeightMultipier.value;
      }

      if (this.maxFilter && this.maxFilter != "") {
        params.average_volume__lt =
          this.maxFilter / this.selectedWeightMultipier.value;
      }

      if (!next) {
        this.collections = [];
      }

      Api.get(
        next
          ? this.nextPage
          : API.BASEURL + API.NOKTA_MARKET_CATEGORIES + this.category.id + "/",
        this.HandleGetCollections,
        params
      );
    },
    HandleGetCollections(status, data) {
      this.$vs.loading.close();
      this.$vs.loading.close("#div-with-loading > .con-vs-loading");

      if (status == 200) {
        if (this.collections.length == 0) {
          this.collections = data.results;
        } else {
          this.collections = this.collections.concat(data.results);
        }

        if (data.next == null) {
          this.nextPage = null;
        } else {
          this.nextPage = data.next;
        }

        if (this.$route.query.collection != null) {
          let index = this.collections.findIndex(
            (e) => e.id == this.$route.query.collection
          );
          this.SendCollection(this.collections[index]);
        }
      }
    },
  },
  computed: {
    selectedWeightMultipier() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
    stoneFilter() {
      return this.$route.query.has_stone;
    },
    orderingFilter() {
      return this.$route.query.ordering;
    },
    minFilter() {
      return this.$route.query.min;
    },
    maxFilter() {
      return this.$route.query.max;
    },
    queryTracker() {
      return this.$route.query;
    },
  },
  watch: {
    queryTracker() {
      this.GetCollection();
    },
  },
  created() {
    this.GetCollection();
  },
  components: {
    Observer,
  },
};
</script>

<style scoped>
.newDiv {
  left: 10px;
  top: 10px;
  position: absolute;
  color: #000;
  font-weight: 600;
  background-color: #fff;
  border-radius: 5px;
  padding: 3px;
  font-size: 0.8rem;
}
.collectionDetail {
  background-color: #000;
  color: #fff;
}
.loadingDiv {
  background-color: #f8f8f8;
  width: 100px;
  height: 100px;
}
</style>
